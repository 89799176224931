import * as React from 'react';
// import { useTranslation } from 'react-i18next';

import { AppPageLayout } from 'src/app.consumer/components';
import { Grid } from 'src/app/components';
import {
  EmailConfirmationForm,
  PasswordlessSigninForm,
  ResetPasswordForm,
  NewsletterUnsubscribeForm,
} from 'src/app/container';
import { AppProps, SeoObject } from 'src/app/types';

interface ActionQueryStringParsedObjectResetPassword {
  mode: string;
  oobCode: string;
  apiKey: string;
  lang: string;
}

const seo: SeoObject = {
  title: 'Actions',
  // TODO: no-follow instruction for robots.txt
};

// TODO: i18n
/* All routes with special trigger-like functionality eg reset password link or confirm email */
export const ActionRoute = (props: AppProps) => {
  const { location } = props;
  // const { t } = useTranslation();
  // const emailApiKey = 'AIzaSyAnDgWq0KuQkXAgawD7q1UVi_X1WVpzV5E'; // FIXME: how to validate? - dev system email api key?
  if (typeof window === 'undefined') {
    return null;
  }

  const currentUrl = new URL(window.location.href);
  const params = new URLSearchParams(currentUrl.search);

  if (params.has('unsubscribeNewsletter')) {
    const id = params.get('unsubscribeNewsletter') || '';
    return (
      <AppPageLayout
        subTitle={''}
        // title={<Localized dictKey={'staticLocales:pages./about.link label'} />}
        title={'Unsubscribe from newsletter'}
        {...{ ...props, seo }}
      >
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} />
          <Grid item md={6} xs={12}>
            <NewsletterUnsubscribeForm id={id} />
          </Grid>
          <Grid item md={3} xs={12} />
        </Grid>
      </AppPageLayout>
    );
  }

  // if href params structure is this signature then most likely it is a firebase action
  if (
    !params.has('mode') ||
    !params.has('oobCode') ||
    !params.has('apiKey') ||
    !params.has('lang')
  ) {
    return null;
  }

  const mode = params.get('mode')!;
  const oobCode = params.get('oobCode')!;
  const apiKey = params.get('apiKey')!;
  const lang = params.get('lang')!;
  if (mode === 'resetPassword') {
    return (
      <AppPageLayout
        subTitle={''}
        // title={<Localized dictKey={'staticLocales:pages./about.link label'} />}
        title={'Reset password'}
        {...{ ...props, seo }}
      >
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} />
          <Grid item md={6} xs={12}>
            <ResetPasswordForm code={oobCode} apiKey={apiKey} lang={lang} />
          </Grid>
          <Grid item md={3} xs={12} />
        </Grid>
      </AppPageLayout>
    );
  }
  if (mode === 'verifyEmail') {
    return (
      <AppPageLayout
        subTitle={''}
        // title={<Localized dictKey={'staticLocales:pages./about.link label'} />}
        title={'Confirm email'}
        {...{ ...props, seo }}
      >
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} />
          <Grid item md={6} xs={12}>
            <EmailConfirmationForm code={oobCode} apiKey={apiKey} lang={lang} />
          </Grid>
          <Grid item md={3} xs={12} />
        </Grid>
      </AppPageLayout>
    );
  }
  if (mode === 'signIn') {
    return (
      <AppPageLayout
        subTitle={''}
        // title={<Localized dictKey={'staticLocales:pages./about.link label'} />}
        title={'Login with email'}
        {...{ ...props, seo }}
      >
        <Grid container spacing={2}>
          <Grid item md={3} xs={12} />
          <Grid item md={6} xs={12}>
            <PasswordlessSigninForm
              code={oobCode}
              apiKey={apiKey}
              lang={lang}
              link={location.href}
            />
          </Grid>
          <Grid item md={3} xs={12} />
        </Grid>
      </AppPageLayout>
    );
  }
  return null;
};

export default ActionRoute;
